export const countQuantity = (count) => {
  const DOZEN = 12;
  const pcs = count % 12 !== 0 ? `${count % 12} pcs` : '';
  return count < DOZEN
    ? `${count} pcs.`
    : `${Math.floor(count / DOZEN)} lusin ${pcs}`;
};

export const countUsedLoyaltyPoint = (total, pointCurrency) => {
  const usedLoyaltyPointsToCurrency = total * pointCurrency;

  return usedLoyaltyPointsToCurrency;
};

export const pcsToDozen = (count) => {
  const DOZEN = 12;
  const pcs = count % 12 !== 0 ? count % 12 : 0;

  return { dozen: Math.floor(count / DOZEN), pcs: pcs };
};

export const dozenToPcs = (dozen, pcs) => {
  return dozen * 12 + pcs;
}

export const skuGenerator = (batch_code, name) => {
  const removeDuplicatesStr = (str) => [...new Set(str)].join('');
  let sku = '';

  sku += batch_code
    ? batch_code
        .replace(/[^a-zA-Z0-9]/g, '')
        .toUpperCase()
        .toUpperCase() + '-'
    : '';

  sku += !name
    ? name
    : name
        .toUpperCase()
        .split(' ')
        .map((w) => {
          const word = /[aeiou]/i.test(w[0])
            ? w[0] + removeDuplicatesStr(w.slice(1)).replace(/[AEIOU]+/g, '')
            : removeDuplicatesStr(w).replace(/[AEIOU]+/g, '');

          return word.slice(0, 3);
        })
        .join('')
        .replace(/[^a-zA-Z0-9]/g, '');

  return sku;
};
