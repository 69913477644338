import { Modal, Form, Input } from 'antd';

const { TextArea } = Input;

const AddCustomer = ({ visible, onCreate, onCancel }) => {
  const [form] = Form.useForm();

  return (
    <Modal
      destroyOnClose
      visible={visible}
      title="Tambah Pelanggan Baru"
      okText="Tambah"
      cancelText="Batal"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.error('Validate Failed:', info);
          });
      }}
    >
      <Form form={form} layout="vertical" name="form_in_modal">
        <Form.Item
          name="customerName"
          label="Nama Pelanggan"
          rules={[
            {
              required: true,
              message: 'Mohon masukkan Nama Pelanggan',
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          name="customerPhone"
          label="Telepon Pelanggan"
          rules={[
            {
              required: true,
              message: 'Mohon masukkan Telepon Pelanggan',
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>

        <Form.Item
          name="addressName"
          label="Nama Toko"
          rules={[
            {
              required: true,
              message: 'Mohon masukkan nama toko',
            },
          ]}
        >
          <Input size="large" placeholder="Misal: Wawan Pekalongan" />
        </Form.Item>

        <Form.Item
          name="customerAddress"
          label="Alamat Toko"
          rules={[
            {
              required: true,
              message: 'Mohon masukkan alamat toko',
            },
          ]}
        >
          <TextArea
            size="large"
            placeholder="Misal: Jl. Dr. Wahidin No. 85 Pekalongan"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddCustomer;
