import { useState } from 'react';
import { Modal, Select, Table } from 'antd';
import { format } from 'date-fns';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

import { useCustomer } from 'libs/hooks/users';
import { useOrderSearch } from 'libs/hooks/order';
import { useCarts } from 'libs/hooks/cart';

const { Option } = Select;

export const columns = [
  {
    title: 'Invoice',
    dataIndex: 'invoice_no',
    key: 'invoice_no',
    render: (_, record) => (
      <div>
        <p style={{ fontSize: 13, margin: 0 }}>
          <strong>Customer:</strong> {record.customer_name}
        </p>
        <p style={{ fontSize: 11, fontWeight: 700 }}>{record.invoice_no}</p>
      </div>
    ),
  },
  {
    title: 'Kasir',
    dataIndex: 'cashier',
    key: 'cashier',
    render: (cashier) => cashier?.name || '-',
  },
  {
    title: 'Barang Sortir',
    dataIndex: 'is_barang_sortir',
    key: 'is_barang_sortir',
    render: (text) => (
      <div>
        {text ? (
          <CheckCircleOutlined style={{ color: 'green' }} />
        ) : (
          <CloseCircleOutlined style={{ color: 'red' }} />
        )}
      </div>
    ),
  },
  {
    title: 'Total',
    dataIndex: 'total',
    key: 'total',
    render: (value) => `Rp. ${value.toLocaleString('id')}`,
  },
  {
    title: 'Tanggal',
    dataIndex: 'invoice_date',
    key: 'invoice_date',
    render: (text) => format(new Date(text), 'dd MMM yyyy'),
  },
];

const RepeatOrder = ({ visible, onSubmit, onCancel }) => {
  const [customer, setCustomer] = useState();
  const [page, setPage] = useState(1);
  const [selected, setSelected] = useState();
  const { data: customers, onSearch } = useCustomer();
  const { data: orders, isLoading } = useOrderSearch(customer);
  const { loading, onBulkAdd } = useCarts();

  const onChangePage = (p) => {
    setPage(p);
  };

  return (
    <Modal
      width={768}
      destroyOnClose
      visible={visible}
      title="Repeat Order"
      okText={loading ? 'Loading...' : 'Repeat Order'}
      cancelText="Batal"
      okButtonProps={{ disabled: loading }}
      onCancel={onCancel}
      onOk={() => {
        if (selected) {
          onBulkAdd(
            selected.items.map((item) => ({
              productID: item.pos_product_id,
              qty: item.quantity,
            }))
          ).finally(onCancel);
        }
      }}
    >
      <Select
        showSearch
        placeholder="Ketik nama pembeli..."
        optionFilterProp="children"
        style={{ width: '100%', maxWidth: '100%' }}
        onSearch={onSearch}
        onSelect={(v) => {
          const customer = (customers?.data || []).find((c) => c.id === v);

          if (customer) {
            setCustomer(customer.id);
          }
        }}
      >
        <Option key={-1} value="">
          -- Pilih Customer --
        </Option>
        {(customers?.data || []).map((c) => {
          return (
            <Option key={c.id} value={c.id}>
              {c.name}
            </Option>
          );
        })}
      </Select>
      <Table
        style={{ marginTop: 16 }}
        loading={isLoading}
        columns={columns}
        size="small"
        dataSource={(orders?.data || []).map((d) => ({ key: d.id, ...d }))}
        onChange={onChangePage}
        scroll={{ y: 240 }}
        rowClassName={(record) =>
          selected?.id === record.id ? 'table-row-selected' : ''
        }
        onRow={(record) => {
          return {
            onClick: () => {
              setSelected(record);
            },
          };
        }}
        pagination={{
          current: page,
          total: orders?.pagination.rowCount || 1,
          showSizeChanger: false,
        }}
      />
    </Modal>
  );
};

export default RepeatOrder;
