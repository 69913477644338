import { Modal, Form, Input } from 'antd';

const OrderCost = ({ visible, onSubmit, onCancel, value }) => {
  const [form] = Form.useForm();

  return (
    <Modal
      destroyOnClose
      visible={visible}
      title="Masukkan Biaya Tambahan"
      okText="Simpan"
      cancelText="Batal"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onSubmit(values);
            onCancel();
            form.resetFields();
          })
          .catch((info) => {
            console.error('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        initialValues={value}
        layout="vertical"
        name="form_in_modal"
      >
        <Form.Item
          label="Jumlah"
          name="additional_cost"
          required
          initialValue="0"
        >
          <Input addonBefore="Rp. " style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item label="Deskripsi" name="additional_cost_desc">
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default OrderCost;
