import useSWR, { mutate } from 'swr';
import { useState, useCallback } from 'react';

import api from 'libs/utils/api';
import useToast from 'libs/utils/toast';

export const useCarts = (type = 'OUT') => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const pathKey = '/pos-cart?cart_type=' + type;
  const { data, error, isValidating } = useSWR(pathKey);

  const onAdd = useCallback(
    async ({ productID, qty }) => {
      try {
        setLoading(true);

        const { data: res } = await api.post('/pos-cart', {
          product_id: productID,
          qty,
          cart_type: type,
        });

        if (res.success) {
          mutate(pathKey);
          toast('success', 'Item berhasil ditambahkan.');
        } else {
          toast('error', 'Terjadi kesalahan ketika menambahkan item');
        }
      } catch (error) {
        toast('error', error.toString());
      } finally {
        setLoading(false);
      }
    },
    [pathKey, toast, type]
  );

  const onAddProductInBySKU = useCallback(
    async (sku) => {
      try {
        setLoading(true);

        const { data: res } = await api.post('/pos-cart/bysku/in', {
          sku: sku,
          qty: 4,
        });

        if (res.success) {
          mutate(pathKey);
          toast('success', 'Item berhasil ditambahkan.');
        } else {
          toast('error', 'Terjadi kesalahan ketika menambahkan item');
        }
      } catch (error) {
        toast('error', error.toString());
      } finally {
        setLoading(false);
      }
    },
    [pathKey, toast]
  );

  const onAddProductOutBySKU = useCallback(
    async (sku) => {
      try {
        setLoading(true);

        const { data: res } = await api.post('/pos-cart/bysku/out', {
          sku: sku,
          qty: 4,
        });

        if (res.success) {
          mutate(pathKey);
          toast('success', 'Item berhasil ditambahkan.');
        } else {
          toast('error', 'Terjadi kesalahan ketika menambahkan item');
        }
      } catch (error) {
        toast('error', error.toString());
      } finally {
        setLoading(false);
      }
    },
    [pathKey, toast]
  );

  const onEdit = useCallback(
    async (id, qty) => {
      try {
        setLoading(true);

        const { data: res } = await api.put('/pos-cart/' + id, {
          qty,
        });

        if (res.success) {
          mutate(pathKey);
          toast('success', 'Kuantiti berhasil diubah.');
        } else {
          toast('error', 'Terjadi kesalahan ketika mengubah kuantiti');
        }
      } catch (error) {
        toast('error', error.toString());
      } finally {
        setLoading(false);
      }
    },
    [pathKey, toast]
  );

  const onBulkAdd = useCallback(
    async (items) => {
      try {
        setLoading(true);

        const { data: res } = await api.post('/pos-cart/bulk', {
          items,
        });

        if (res.success) {
          mutate(pathKey);
          toast('success', 'Berhasil menambahkan item ke keranjang.');
        } else {
          toast(
            'error',
            'Terjadi kesalahan ketika menambahkan produk ke keranjang'
          );
        }
      } catch (error) {
        toast('error', error.toString());
      } finally {
        setLoading(false);
      }
    },
    [pathKey, toast]
  );

  const onDelete = useCallback(
    async (IDs) => {
      try {
        setLoading(true);

        const { data: res } = await api.delete('/pos-cart', {
          data: { ids: Array.isArray(IDs) ? IDs : [IDs] },
        });

        if (res.success) {
          mutate(pathKey);
          toast('success', 'Item berhasil dihapus.');
        } else {
          toast('error', 'Terjadi kesalahan ketika menghapus item');
        }
      } catch (error) {
        toast('error', error.toString());
      } finally {
        setLoading(false);
      }
    },
    [pathKey, toast]
  );

  return {
    data,
    loading: (!error && !data) || isValidating || loading,
    onAdd,
    onEdit,
    onDelete,
    onBulkAdd,
    onAddProductInBySKU,
    onAddProductOutBySKU,
  };
};
