import useSWR, { mutate } from 'swr';
import { useState, useCallback } from 'react';
import format from 'date-fns/format';

import api from 'libs/utils/api';
import useToast from 'libs/utils/toast';

export const useOrder = (querystring = '') => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const pathKey = '/pos-order?' + querystring;
  const { data, error, isValidating } = useSWR(pathKey);

  const onSubmit = useCallback(
    async (data) => {
      try {
        setLoading(true);

        const { data: res } = await api.post('/pos-order', {
          invoice_date: format(data.invoice_date, 'yyyy-MM-dd'),
          customer_id: data.customer_id,
          address_id: data.address_id,
          discount: data.discount,
          discount_desc: data.discount_desc,
          additional_cost: data.additional_cost,
          additional_cost_desc: data.additional_cost_desc,
          is_invoice_barang_sortir: data.is_invoice_barang_sortir || false,
          is_use_point: data.is_use_point,
        });

        if (res.success) {
          mutate(pathKey);
          mutate('/pos-cart?cart_type=OUT'); // default path for carts
          toast('success', 'Pesanan berhasil ditambahkan.');

          return res?.data;
        } else {
          toast('error', 'Terjadi kesalahan ketika menambahkan pesanan');
        }
      } catch (error) {
        toast('error', error.toString());
      } finally {
        setLoading(false);
      }
    },
    [pathKey, toast]
  );

  return {
    data,
    loading: (!error && !data) || isValidating || loading,
    onSubmit,
  };
};

export const useOrderSearch = (customerID = '') => {
  const pathKey = '/pos-order?customer_id=' + customerID;
  const { data, error, isValidating } = useSWR(customerID ? pathKey : null);

  return {
    data,
    loading: (!error && !data) || isValidating,
  };
};

export const useOrderDetail = (orderID) => {
  const pathKey = `/pos-order/${orderID}/detail`;
  const { data, error, isValidating } = useSWR(pathKey);

  return {
    data,
    loading: (!error && !data) || isValidating,
  };
};
