import { Card, Button } from 'antd';

import { getImage } from 'libs/utils/image';

import { countQuantity } from 'libs/utils/number';
const { Meta } = Card;

const ProductCard = ({ name, pictures, stockQty: stock, onSelect }) => {
  let productPictures = [];
  try {
    productPictures = JSON.parse(pictures);
  } catch (error) {}

  return (
    <Card
      className="product-search"
      cover={<img alt={name} src={getImage(productPictures[0], 200, '3:4')} />}
      actions={[
        <Button type="primary" onClick={onSelect}>
          Pilih Item
        </Button>,
      ]}
    >
      <Meta title={name} description={`Stock: ${countQuantity(stock)}`} />
    </Card>
  );
};

export default ProductCard;
