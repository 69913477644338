import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Row,
  Card,
  Col,
  Table,
  PageHeader,
  Button,
  Form,
  DatePicker,
} from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import moment from 'moment';

import { useProductsPopular } from 'libs/hooks/products';
import { useQuery } from 'libs/utils/route';
import { columns } from 'pages/product-popular/utils';

const Product = () => {
  const history = useHistory();
  const query = useQuery();
  const [form] = Form.useForm();

  const [isFilterOpen, setFilterOpen] = useState(false);

  const { data: res, isLoading } = useProductsPopular(query.toString());

  const handleFilter = (value) => {
    if (value.date) query.set('date', value.date.format('YYYY-MM-DD'));
    history.push(`/d/products/popular?${query.toString()}`);
  };

  return (
    <>
      <PageHeader
        ghost={false}
        title="Produk Terlaris"
        extra={[
          <Button
            key="filter"
            onClick={() => setFilterOpen(!isFilterOpen)}
            icon={<FilterOutlined />}
          >
            {isFilterOpen ? 'Sembunyikan Filter' : 'Filter'}
          </Button>,
        ]}
      />

      {isFilterOpen && (
        <Card>
          <Form layout="vertical" form={form} onFinish={handleFilter}>
            <Row gutter={[16]}>
              <Col span={6}>
                <Form.Item name="date" label="Pilih Tanggal">
                  <DatePicker
                    style={{ width: '100%', maxWidth: '100%' }}
                    placeholder="Pilih tanggal..."
                    format="DD/MMM/yyyy"
                    defaultValue={moment()}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ marginRight: 8 }}
                >
                  Terapkan
                </Button>

                <Button
                  type="primary"
                  htmlType="reset"
                  onClick={() => {
                    form.resetFields();
                    history.push('/d/products/popular');
                  }}
                  danger
                >
                  Reset
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      )}

      <Card style={{ marginTop: 16 }}>
        <Table
          loading={isLoading}
          columns={columns}
          dataSource={res?.data}
          pagination={false}
        />
      </Card>
    </>
  );
};

export default Product;
