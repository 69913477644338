import { Row, Col, Space, Table, PageHeader, Descriptions, Card } from 'antd';
import { format } from 'date-fns';
import { useParams, useHistory } from 'react-router-dom';

import { countQuantity } from 'libs/utils/number';
import { useProductReceival } from 'libs/hooks/products';

const columns = [
  {
    title: 'Nama',
    dataIndex: 'name',
    key: 'name',
    render: (_, record) =>
      record.pos_product.detail.on_sale
        ? `${record.pos_product.detail.name} (disc ${record.pos_product.detail.sale_price}%)`
        : record.pos_product.detail.name,
  },
  {
    title: 'Gambar',
    dataIndex: 'picture',
    key: 'picture',
    render: (_, record) => {
      let pictures = [];
      try {
        pictures = JSON.parse(record.pos_product.detail.pictures);
      } catch (error) {}

      return (
        <Space size="middle">
          {pictures.map((picture, idx) => (
            <div
              style={{ maxWidth: 60, overflow: 'hidden', borderRadius: 4 }}
              key={idx}
            >
              <img
                src={picture}
                alt="slider"
                style={{ width: '100%', height: '100%' }}
              />
            </div>
          ))}
        </Space>
      );
    },
  },
  {
    title: 'Harga Barang',
    dataIndex: 'price',
    key: 'price',
    render: (_, record) =>
      record.pos_product.detail.on_sale
        ? `Rp. ${record.pos_product.detail.sale_price.toLocaleString('ID')}`
        : `Rp. ${record.pos_product.detail.price.toLocaleString('ID')}`,
  },
  {
    title: 'Banyak Pembelian',
    dataIndex: 'qty',
    key: 'qty',
    render: (text) => countQuantity(text),
  },
  {
    title: 'Subtotal',
    dataIndex: 'subtotal',
    key: 'subtotal',
    render: (_, record) => {
      const price = record.pos_product.detail.on_sale
        ? record.pos_product.detail.sale_price
        : record.pos_product.detail.price;
      const subtotal = record.qty * price;
      return `Rp. ${subtotal.toLocaleString('ID')}`;
    },
  },
];

const Detail = () => {
  const history = useHistory();
  const { id } = useParams();
  const { data: order } = useProductReceival(id);

  return (
    <>
      <PageHeader
        title="Detail Barang Masuk"
        subTitle={order ? order.invoice_no : ''}
        style={{ padding: '0 0 16px' }}
        onBack={() => history.goBack()}
      />
      <Row>
        {order?.data ? (
          <>
            <Col span={24} style={{ marginBottom: 32 }}>
              <Card title="Deskripsi">
                <Descriptions bordered size="small">
                  <Descriptions.Item label="Tanggal">
                    {format(new Date(order?.data?.invoice_date), 'dd-MMM-yyyy')}
                  </Descriptions.Item>
                  <Descriptions.Item label="Total" span="3">
                    <strong>
                      {order?.data?.total
                        ? `Rp. ${order?.data?.total.toLocaleString('id')}`
                        : null}
                    </strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="Aktor">
                    {order?.data?.actor}
                  </Descriptions.Item>
                  <Descriptions.Item label="Barang BS">
                    {order?.data?.is_barang_sortir ? 'Ya' : 'Tidak'}
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            </Col>
            <Col span={24} style={{ marginBottom: 32 }}>
              <Card title="Daftar Item">
                <Table columns={columns} dataSource={order?.data?.items} />
              </Card>
            </Col>
          </>
        ) : null}
      </Row>
    </>
  );
};

export default Detail;
