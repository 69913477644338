import { Row, Col, Space, Table, PageHeader, Descriptions, Card } from 'antd';
import { format } from 'date-fns';
import { useParams, useHistory } from 'react-router-dom';

import { countQuantity, countUsedLoyaltyPoint } from 'libs/utils/number';
import { useOrderDetail } from 'libs/hooks/order';
import { useOptions } from 'libs/hooks/users';

const columns = [
  {
    title: 'Nama',
    dataIndex: 'name',
    key: 'name',
    render: (_, record) =>
      record.product.detail.on_sale
        ? `${record.product.detail.name} (disc ${record.product.detail.sale_price}%)`
        : record.product.detail.name,
  },
  {
    title: 'Gambar',
    dataIndex: 'picture',
    key: 'picture',
    render: (_, record) => {
      let pictures = [];
      try {
        pictures = JSON.parse(record.product.detail.pictures);
      } catch (error) {}

      return (
        <Space size="middle">
          {pictures.map((picture, idx) => (
            <div
              style={{ maxWidth: 60, overflow: 'hidden', borderRadius: 4 }}
              key={idx}
            >
              <img
                src={picture}
                alt="slider"
                style={{ width: '100%', height: '100%' }}
              />
            </div>
          ))}
        </Space>
      );
    },
  },
  {
    title: 'Harga Barang',
    dataIndex: 'price',
    key: 'price',
    render: (_, record) =>
      record.discount_price !== 0
        ? `Rp. ${record.discount_price.toLocaleString('ID')}`
        : `Rp. ${record.price.toLocaleString('ID')}`,
  },
  {
    title: 'Banyak Pembelian',
    dataIndex: 'qty',
    key: 'qty',
    render: (_, record) => countQuantity(record.quantity),
  },
  {
    title: 'Subtotal',
    dataIndex: 'subtotal',
    key: 'subtotal',
    render: (_, record) => {
      return `Rp. ${record.subtotal.toLocaleString('ID')}`;
    },
  },
];

const OrderDetail = () => {
  const history = useHistory();
  const { id } = useParams();
  const { data: order } = useOrderDetail(id);
  const { data: options } = useOptions();

  let mappedOptions;

  if (options?.data?.length !== 0) {
    let split;
    mappedOptions = options?.data.map((option) => {
      split = {
        ...split,
        [option.key]: option.value,
      };

      return split;
    });

    if (mappedOptions) {
      let mappedOptionsLength = mappedOptions.length;
      mappedOptions = mappedOptions[mappedOptionsLength - 1];
    }
  }

  return (
    <>
      <PageHeader
        title="Detail Pesanan"
        subTitle={order ? order.invoice_no : ''}
        style={{ padding: '0 0 16px' }}
        onBack={() => history.goBack()}
      />
      <Row>
        {order?.data ? (
          <>
            <Col span={24} style={{ marginBottom: 32 }}>
              <Card title={order?.data.invoice_no}>
                <Descriptions bordered size="small">
                  <Descriptions.Item label="Tanggal">
                    {format(new Date(order?.data?.invoice_date), 'dd-MMM-yyyy')}
                  </Descriptions.Item>
                  <Descriptions.Item label="Total" span="3">
                    <strong>
                      {order?.data?.total
                        ? `Rp. ${order?.data?.total.toLocaleString('id')}`
                        : null}
                    </strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="Pelanggan">
                    {order?.data?.customer_name}
                  </Descriptions.Item>
                  <Descriptions.Item label="Diskon">
                    {order?.data?.discount_desc}: {order?.data?.discount}
                  </Descriptions.Item>
                  <Descriptions.Item label={order?.data?.additional_cost_desc}>
                    {order?.data?.additional_cost}
                  </Descriptions.Item>
                  <Descriptions.Item label="Loyalty point yang digunakan">
                    {!order?.data?.is_use_point ? 0 : `${order?.data?.total_point_used} (Rp. ${countUsedLoyaltyPoint(order?.data.total_point_used, mappedOptions?.point_currency).toLocaleString('id')})`}
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            </Col>
            <Col span={24} style={{ marginBottom: 32 }}>
              <Card title="Daftar Item">
                <Table columns={columns} dataSource={order?.data?.items} />
              </Card>
            </Col>
          </>
        ) : null}
      </Row>
    </>
  );
};

export default OrderDetail;
