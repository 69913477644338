import useSWR, { mutate } from 'swr';
import { useCallback, useState } from 'react';
import moment from 'moment';

import useToast from 'libs/utils/toast';
import api from 'libs/utils/api';
import { debounce } from 'lodash';

export const useProductReceivals = (querystring = '') => {
  const toast = useToast();
  const pathKey = `/pos-product-histories?${querystring}`;
  const { data, error, isValidating } = useSWR(pathKey);

  const onAdd = useCallback(
    async (payload) => {
      try {
        const { data: res } = await api.post('/pos-product-histories', {
          invoice_date: payload.invDate,
          is_barang_sortir: payload.isBS,
        });

        if (res.success) {
          mutate(pathKey);
          mutate('/pos-cart?cart_type=IN'); // default path for carts
          toast('success', 'Data berhasil disimpan');
        } else {
          toast('error', 'Data gagal disimpan');
        }
      } catch (error) {
        toast('error', error.toString());
      }
    },
    [toast, pathKey]
  );

  return {
    data,
    isLoading: (!error && !data?.data) || isValidating,
    isError: error,
    onAdd,
  };
};

export const useProductSum = (querystring = '') => {
  const pathKey = `/pos-product-sum?${querystring}`;
  const { data, error, isValidating } = useSWR(pathKey);

  return {
    data,
    isLoading: (!error && !data?.data) || isValidating,
    isError: error,
  };
};

export const useProductQty = (querystring = '') => {
  const pathKey = `/pos-product-qty?${querystring}`;
  const { data, error, isValidating } = useSWR(pathKey);

  return {
    data,
    isLoading: (!error && !data?.data) || isValidating,
    isError: error,
  };
};

export const useProductReceival = (id) => {
  const pathKey = `/pos-product-histories/${id}`;
  const { data, error, isValidating } = useSWR(() => (id ? pathKey : null));

  return {
    data,
    isLoading: (!error && !data?.data) || isValidating,
    isError: error,
  };
};

export const useProducts = (querystring = '') => {
  const pathKey = `/pos-product?${querystring}`;
  const { data, error, isValidating } = useSWR(pathKey);

  return {
    data,
    isLoading: (!error && !data?.data) || isValidating,
    isError: error,
  };
};

export const useProductsPopular = (querystring = '') => {
  const pathKey = `/pos-order-popular?${
    querystring ? querystring : `date=${moment().format('YYYY-MM-DD')}`
  }`;
  const { data, error, isValidating } = useSWR(pathKey);

  return {
    data,
    isLoading: (!error && !data?.data) || isValidating,
    isError: error,
  };
};

export const useProductsIn = (querystring = '') => {
  const pathKey = `/pos-product-histories?${querystring}`;
  const { data, error, isValidating } = useSWR(pathKey);

  return {
    data,
    isLoading: (!error && !data?.data) || isValidating,
    isError: error,
  };
};

export const useCategories = () => {
  const [name, setName] = useState('');
  const pathKey = `/category?name=${name}`;
  const { data, error, isValidating } = useSWR(name ? pathKey : null);

  const onSearch = debounce(
    useCallback((value) => {
      setName(value);
    }, []),
    300
  );

  return {
    data: data,
    isLoading: (!error && !data?.data) || isValidating,
    isError: error,
    onSearch,
  };
};
