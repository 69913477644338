import { Modal, Form, Input, InputNumber } from 'antd';

const OrderDisc = ({ visible, onSubmit, onCancel, value, total }) => {
  const [form] = Form.useForm();

  return (
    <Modal
      destroyOnClose
      visible={visible}
      title="Masukkan Diskon"
      okText="Simpan"
      cancelText="Batal"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onSubmit(values);
            onCancel();
            form.resetFields();
          })
          .catch((info) => {
            console.error('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        initialValues={{
          percentage: total > 0 ? ((value.discount / total) * 100).toFixed(2) : 0,
          value,
        }}
        layout="vertical"
        name="form_in_modal"
      >
        <Form.Item
          label="Diskon dalam persen"
          name="percentage"
          required
        >
          <InputNumber
            style={{ width: '100%' }}
            min={0}
            max={100}
            formatter={(value) => `${value}%`}
            parser={(value) => value.replace('%', '')}
            onChange={(value) => {
              if (value) {
                const disc = (total * value) / 100;
                form.setFieldsValue({
                  percentage: value,
                  discount: disc,
                });
              }
            }}
          />
        </Form.Item>

        <Form.Item label="Jumlah" name="discount" required initialValue="0">
          <Input
            addonBefore="Rp. "
            onChange={({ target }) => {
              if (target.value) {
                const percent = ((target.value / total) * 100).toFixed(2);
                form.setFieldsValue({
                  percentage: percent,
                  discount: target.value,
                });
              }
            }}
          />
        </Form.Item>

        <Form.Item label="Deskripsi" name="discount_desc">
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default OrderDisc;
