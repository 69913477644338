import { Button, Modal } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { countQuantity } from 'libs/utils/number';

const { confirm } = Modal;

export const columns = (onDelete, setRow) => [
  {
    title: 'Nama',
    dataIndex: 'name',
    key: 'name',
    render: (_, { pos_product }) => <span>{pos_product.detail.name}</span>,
  },
  {
    title: 'Harga',
    dataIndex: 'price',
    key: 'price',
    render: (_, { pos_product }) => {
      return pos_product.detail.on_sale ? (
        <>
          <span
            style={{ textDecoration: 'line-through' }}
          >{`Rp. ${pos_product.detail.price.toLocaleString('ID')} `}</span>
          <span style={{ color: 'red' }}>{`Rp. ${(
            pos_product.detail.price -
            (pos_product.detail.sale_price / 100) * pos_product.detail.price
          ).toLocaleString('ID')}`}</span>
        </>
      ) : (
        `Rp. ${pos_product.detail.price.toLocaleString('ID')}`
      );
    },
  },
  {
    title: 'Qty',
    dataIndex: 'qty',
    key: 'qty',
    render: (_, record) => <span>{countQuantity(record.qty)}</span>,
  },
  {
    title: 'Subtotal',
    key: 'subtotal',
    render: (_, { pos_product, qty }) => {
      const price = pos_product.detail.on_sale
        ? pos_product.detail.price -
          (pos_product.detail.sale_price / 100) * pos_product.detail.price
        : pos_product.detail.price;

      return <span>Rp. {(price * (qty || 0)).toLocaleString('ID')}</span>;
    },
  },
  {
    title: 'Qty',
    dataIndex: 'qty',
    key: 'qty',
    render: (_, record) => <span>{countQuantity(record.qty)}</span>,
  },
  {
    title: 'Opsi',
    dataIndex: 'id',
    key: 'id',
    render: (id, record) => {
      return (
        <>
          <Button type="link" onClick={() => setRow(record)}>
            <EditOutlined />
          </Button>
          <Button
            type="link"
            danger
            onClick={() =>
              confirm({
                title: 'Hapus Item',
                content: 'Apakah anda yakin ingin menghapus item ini?',
                onOk: () => onDelete(id),
              })
            }
          >
            <DeleteOutlined />
          </Button>
        </>
      );
    },
  },
];
