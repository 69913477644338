import { useCallback, useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import {
  PageHeader,
  Card,
  Col,
  Row,
  Select,
  Form,
  Switch,
  Table,
  Button,
  Typography,
  Empty,
  Divider,
  Badge,
} from "antd";
import {
  RetweetOutlined,
  FileSearchOutlined,
  PrinterOutlined,
  SaveOutlined,
  PercentageOutlined,
  DollarCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import {
  SearchPOSProduct,
  ProductCard,
  Quantifier,
  OrderDisc,
  OrderCost,
  RepeatOrder,
  DatePicker,
  AddCustomerSales,
} from "components";
import { useCarts } from "libs/hooks/cart";
import { useCustomer, useOptions, useUsers } from "libs/hooks/users";
import { useOrder } from "libs/hooks/order";
import { columns } from "pages/sales/utils";

const { Option } = Select;
const { Title } = Typography;

const storageDiscount = window.localStorage.getItem("sales_discount");
const storageCost = window.localStorage.getItem("sales_cost");

const Sales = () => {
  let defaultDiscount = null;
  try {
    defaultDiscount = JSON.parse(storageDiscount) || {
      discount: 0,
      discount_desc: "Diskon",
    };
  } catch (error) {}

  let defaultCost = null;
  try {
    defaultCost = JSON.parse(storageCost) || {
      additional_cost: 0,
      additional_cost_desc: "Biaya Tambahan",
    };
  } catch (error) {}

  const [row, setRow] = useState();
  const [selected, setSelected] = useState();
  const [products, setProducts] = useState([]);
  const [customer, setCustomer] = useState("");
  const [dc, setDC] = useState();
  const [isRepeatOrder, setRepeatOrder] = useState(false);
  const [discount, setDiscount] = useState(defaultDiscount);
  const [cost, setCost] = useState(defaultCost);
  const [visible, setVisible] = useState(false);
  const [newCustomer, setNewCustomer] = useState();
  const [sku, setSku] = useState();
  const getScanBarcode = window.localStorage.getItem("isScanBarcode");
  const [isScanBarcode, setScanBarcode] = useState(getScanBarcode === "1");
  const [isFocus, setFocus] = useState(false);
  const [useLoyaltyPoint, setUseLoyaltyPoint] = useState(false);
  const [loyaltyPoint, setLoyaltyPoint] = useState(0);
  const inputSKU = useRef();

  const {
    data: carts,
    loading,
    onAdd,
    onEdit,
    onDelete,
    onAddProductOutBySKU,
  } = useCarts();

  const { data: options } = useOptions();
  const { data: customers, onSearch } = useCustomer();
  const { onSubmit } = useOrder();
  const { onAdd: onAddCustomer, onFetchDetail: onFetchUserDetail } = useUsers();

  const history = useHistory();
  const [form] = Form.useForm();

  const total = (carts?.data || []).reduce((t, { pos_product, qty }) => {
    const price = pos_product.detail.on_sale
      ? pos_product.detail.price -
        (pos_product.detail.sale_price / 100) * pos_product.detail.price
      : pos_product.detail.price;

    t += price * qty;
    return t;
  }, 0);

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        onSubmit({
          ...values,
          ...discount,
          ...cost,
          is_use_point: useLoyaltyPoint,
        });

        setDiscount(defaultDiscount);
        setCost(defaultCost);
        setUseLoyaltyPoint(false);
        setLoyaltyPoint(0);
        window.localStorage.removeItem("sales_discount");
        window.localStorage.removeItem("sales_cost");
      })
      .catch((info) => {
        console.error("Validate Failed:", info);
      });
  };

  const handleSaveAndPrint = () => {
    form
      .validateFields()
      .then((values) => {
        onSubmit({
          ...values,
          ...discount,
          ...cost,
          is_use_point: useLoyaltyPoint,
        }).then((res) => {
          form.resetFields();

          setDiscount(defaultDiscount);
          setCost(defaultCost);
          setUseLoyaltyPoint(false);
          setLoyaltyPoint(0);

          window.localStorage.removeItem("sales_discount");
          window.localStorage.removeItem("sales_cost");

          window.open(`/d/orders/${res?.id}/invoice`);
        });
      })
      .catch((info) => {
        console.error("Validate Failed:", info);
      });
  };

  const handleFormValuesChange = (changedValues) => {
    const formFieldName = Object.keys(changedValues)[0];
    if (formFieldName === "customer_id") {
      setCustomer(changedValues[formFieldName]);
      form.setFieldsValue({ address_id: undefined });
    }
  };

  const handleLoyaltyPointChecked = (checked) => {
    if (customer?.loyaltyPoint?.balance > 0) {
      const pointCurrency = options.data.find(
        (option) => option.key === "point_currency"
      );
      const parsedBalance = customer?.loyaltyPoint?.balance * Number(pointCurrency.value);

      setLoyaltyPoint(checked ? parsedBalance : 0);
      setUseLoyaltyPoint(checked);
      form.setFieldsValue({ is_use_point: checked });
    }
  };

  useEffect(() => {
    let timeout;
    if (newCustomer) {
      timeout = setTimeout(() => {
        form.setFieldsValue({
          customer_id: newCustomer.id,
        });
      }, 300);
    }

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line
  }, [newCustomer]);

  const handleCreateUser = useCallback((values) => {
    const data = {
      ...values,
      password: "1234",
    };

    onAddCustomer(data).then((res) => setNewCustomer(res));
    setVisible(false);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <PageHeader
        ghost={false}
        title="Penjualan"
        style={{ marginBottom: 16 }}
        extra={[
          <Button
            key="repeat_invoice"
            icon={<RetweetOutlined />}
            onClick={() => setRepeatOrder(true)}
          >
            Repeat Nota
          </Button>,
          <Button
            key="find_invoice"
            icon={<FileSearchOutlined />}
            onClick={() => history.push("/d/orders")}
          >
            Cari Nota
          </Button>,
          <Button key="save" icon={<SaveOutlined />} onClick={handleSubmit}>
            Simpan
          </Button>,
          <Button
            key="save_print"
            type="primary"
            icon={<PrinterOutlined />}
            onClick={handleSaveAndPrint}
          >
            Simpan &amp; Cetak
          </Button>,
        ]}
      />
      <RepeatOrder
        visible={isRepeatOrder}
        onCancel={() => setRepeatOrder(false)}
      />
      <Row gutter={[16, 16]}>
        <Col span={12}>
          {isScanBarcode ? (
            <Card
              title="Scanner"
              size="small"
              style={{ height: "100%" }}
              extra={
                <Button
                  onClick={() => {
                    setScanBarcode(false);
                    window.localStorage.setItem("isScanBarcode", 0);
                  }}
                >
                  Cari Manual
                </Button>
              }
            >
              <input
                ref={inputSKU}
                autoFocus
                value={sku}
                style={{ opacity: 0, width: 0 }}
                onChange={(e) => setSku(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onAddProductOutBySKU(sku);
                    setSku("");
                  }
                }}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
              />
              <Badge
                color={isFocus ? "green" : "red"}
                text={
                  isFocus ? (
                    <Button disabled>Connected</Button>
                  ) : (
                    <Button onClick={() => inputSKU.current.focus()}>
                      Reconnect
                    </Button>
                  )
                }
              />
            </Card>
          ) : (
            <Card
              title="Cari Manual"
              size="small"
              style={{ height: "100%" }}
              extra={
                <Button
                  onClick={() => {
                    setScanBarcode(true);
                    window.localStorage.setItem("isScanBarcode", 1);
                  }}
                >
                  Gunakan Scanner
                </Button>
              }
            >
              <SearchPOSProduct
                placeholder="Ketik nama barang disini..."
                onSearch={(result) => setProducts(result)}
              />

              <div
                style={{
                  flexDirection: "column",
                  overflow: "auto",
                  height: "100%",
                  maxHeight: "540px",
                  marginTop: 16,
                }}
              >
                <Row gutter={[16, 16]}>
                  {!products.length ? (
                    <Col offset={8} span={8}>
                      <Empty description="Barang belum ditemukan. Cari kata kunci lainnya" />
                    </Col>
                  ) : null}
                  {products.map((p, idx) => (
                    <Col key={idx} xs={24} sm={24} md={12} lg={12} xl={8}>
                      <ProductCard
                        {...p.detail}
                        stockQty={p.stock_qty}
                        onSelect={() => setSelected(p.id)}
                      />
                    </Col>
                  ))}
                </Row>
              </div>
              <Quantifier
                onSubmit={(qty) => {
                  onAdd({
                    productID: selected,
                    qty,
                  });
                  setSelected(null);
                }}
                visible={!!selected}
                onCancel={() => setSelected(null)}
              />
            </Card>
          )}
        </Col>
        <Col span={12}>
          <Card size="small" style={{ height: "100%" }}>
            <Form
              layout="vertical"
              form={form}
              onValuesChange={handleFormValuesChange}
            >
              <Form.Item
                label="Tanggal"
                name="invoice_date"
                required
                initialValue={new Date()}
                rules={[
                  {
                    required: true,
                    message: "Mohon pilih tanggal",
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%", maxWidth: "100%" }}
                  placeholder="Pilih tanggal..."
                  format="DD/MMM/yyyy"
                />
              </Form.Item>
              <Form.Item
                label="Pembeli"
                name="customer_id"
                rules={[
                  {
                    required: true,
                    message: "Mohon pilih customer",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Ketik nama pembeli..."
                  optionFilterProp="children"
                  style={{ width: "100%", maxWidth: "100%" }}
                  onSearch={onSearch}
                  onSelect={(v) => {
                    const customer = (customers?.data || []).find(
                      (c) => c.id === v
                    );

                    if (customer) {
                      onFetchUserDetail(customer.id).then((res) =>
                        setCustomer(res)
                      );
                    }
                  }}
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      <Divider style={{ margin: "4px 0" }} />
                      <div style={{ margin: 8 }}>
                        <Button
                          type="primary"
                          icon={<PlusOutlined />}
                          size="small"
                          onClick={() => setVisible(true)}
                        >
                          Pelanggan Baru
                        </Button>
                      </div>
                    </div>
                  )}
                >
                  <Option key={-1} value="">
                    -- Pilih Customer --
                  </Option>
                  {(
                    customers?.data ||
                    (newCustomer
                      ? [{ id: newCustomer.id, name: newCustomer.name }]
                      : [])
                  ).map((c) => {
                    return (
                      <Option key={c.id} value={c.id}>
                        {c.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item label="Toko/Alamat" name="address_id">
                <Select
                  showSearch
                  placeholder="Ketik nama toko..."
                  optionFilterProp="children"
                  style={{ width: "100%", maxWidth: "100%" }}
                >
                  {(customer?.address || []).map((a) => {
                    return (
                      <Option key={a.id} value={a.id}>
                        {a.name} - {a.address}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                label="Barang BS"
                name="is_invoice_barang_sortir"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Form>

            <div
              style={{
                display: "flex",
              }}
            >
              <Button
                icon={<PercentageOutlined />}
                onClick={() => setDC("discount")}
              >
                Diskon
              </Button>

              <Button
                icon={<DollarCircleOutlined />}
                style={{ marginLeft: 16 }}
                onClick={() => setDC("additional_cost")}
              >
                Biaya Tambahan
              </Button>
            </div>

            <Card size="small" bordered style={{ margin: "16px 0 0 0" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div style={{ marginBottom: 8 }}>
                  <span
                    style={{
                      fontWeight: 600,
                      width: "150px",
                      display: "inline-block",
                    }}
                  >
                    Point Tersedia
                  </span>
                  <span style={{ display: "inline-block", marginRight: 8 }}>
                    :
                  </span>{" "}
                  {customer?.loyaltyPoint?.balance || 0} point
                </div>
                <div>
                  <span
                    style={{
                      fontWeight: 600,
                      width: "150px",
                      display: "inline-block",
                    }}
                  >
                    Pakai Loyalty Point
                  </span>
                  <span style={{ display: "inline-block", marginRight: 8 }}>
                    :
                  </span>
                  <Switch
                    onChange={handleLoyaltyPointChecked}
                    checked={useLoyaltyPoint}
                    disabled={customer?.loyaltyPoint?.balance < 1}
                  />
                </div>

                {customer?.loyaltyPoint?.balance < 1 && (
                  <div style={{ marginTop: "10px", color: "#ff4d4f" }}>
                    {customer?.name} belum memiliki point.
                  </div>
                )}
              </div>
            </Card>
            <Card size="small" bordered style={{ margin: "16px 0 0 0" }}>
              <Typography style={{ textAlign: "right" }} level={5}>
                {discount.discount_desc}: Rp.{" "}
                {discount.discount.toLocaleString("ID")}
              </Typography>

              <Typography style={{ textAlign: "right" }} level={5}>
                {cost.additional_cost_desc}: Rp.{" "}
                {cost.additional_cost.toLocaleString("ID")}
              </Typography>

              {useLoyaltyPoint && (
                <Typography style={{ textAlign: "right" }} level={5}>
                  Loyalty Point yang digunakan: Rp.{" "}
                  {loyaltyPoint.toLocaleString("ID")}
                </Typography>
              )}

              <Title style={{ textAlign: "right" }} level={5}>
                Total: Rp.{" "}
                {(
                  total -
                  (+discount.discount || 0) +
                  (+cost.additional_cost || 0) -
                  (+loyaltyPoint || 0)
                ).toLocaleString("ID")}
              </Title>
            </Card>
          </Card>
        </Col>
        <OrderDisc
          visible={dc === "discount"}
          onCancel={() => setDC(null)}
          value={discount}
          total={total}
          onSubmit={(v) => {
            setDiscount(v);
            window.localStorage.setItem("sales_discount", JSON.stringify(v));
          }}
        />
        <OrderCost
          visible={dc === "additional_cost"}
          onCancel={() => setDC(null)}
          value={cost}
          onSubmit={(v) => {
            setCost(v);
            window.localStorage.setItem("sales_cost", JSON.stringify(v));
          }}
        />
      </Row>

      <Table
        style={{ marginTop: 16 }}
        columns={columns(onDelete, setRow)}
        loading={loading}
        dataSource={carts?.data.map((c) => ({ key: c.id, ...c }))}
        size="small"
      />
      {row ? (
        <Quantifier
          onSubmit={(qty) => {
            onEdit(row.id, qty);
            setRow(null);
          }}
          visible={!!row}
          initialValue={row.qty}
          onCancel={() => setRow(null)}
        />
      ) : null}
      <AddCustomerSales
        visible={visible}
        onCreate={handleCreateUser}
        onCancel={() => setVisible(false)}
      />
    </>
  );
};

export default Sales;
