/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Table, PageHeader, Button, Card, Form, Input, List } from 'antd';
import { PlusOutlined, FilterOutlined } from '@ant-design/icons';

import { AddCustomer } from 'components';
import { useUsers } from 'libs/hooks/users';
import { useQuery } from 'libs/utils/route';
import { columns } from './utils';

const Customer = () => {
  const history = useHistory();
  const query = useQuery();
  const [form] = Form.useForm();

  const [isFilterOpen, setFilterOpen] = useState(false);
  const [visible, setVisible] = useState(false);

  const { data: res, isLoading, onAdd } = useUsers(query.toString());

  const onChangePage = (p) => {
    query.set('page', p.current);
    history.push(`/d/customers?${query.toString()}`);
  };

  const handleFilter = (value) => {
    query.set('name', value.name);
    query.delete('page');

    history.push(`/d/customers?${query.toString()}`);
  };

  const handleCreateUser = (values) => {
    onAdd(values);
    setVisible(false);
  };

  return (
    <>
      <PageHeader
        ghost={false}
        title="Daftar Pelanggan"
        extra={[
          <Button
            key={1}
            type="primary"
            onClick={() => setVisible(true)}
            icon={<PlusOutlined />}
          >
            Tambah Pelanggan
          </Button>,
          <Button
            key="filter"
            onClick={() => setFilterOpen(!isFilterOpen)}
            icon={<FilterOutlined />}
          >
            {isFilterOpen ? 'Sembunyikan Filter' : 'Filter'}
          </Button>,
        ]}
      />

      {isFilterOpen && (
        <Card>
          <Form layout="vertical" form={form} onFinish={handleFilter}>
            <Col span={6}>
              <Form.Item name="name">
                <Input placeholder="Nama" />
              </Form.Item>
            </Col>
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginRight: 8 }}
              >
                Terapkan
              </Button>

              <Button
                type="primary"
                htmlType="reset"
                onClick={() => {
                  form.resetFields();
                  history.push('/d/customers');
                }}
                danger
              >
                Reset
              </Button>
            </Col>
          </Form>
        </Card>
      )}

      <Table
        style={{ marginTop: 16 }}
        loading={isLoading}
        columns={columns}
        dataSource={res?.data.map((d) => ({ key: d.id, ...d }))}
        onChange={onChangePage}
        pagination={{
          current: +query.get('page') || 1,
          total: res?.pagination.rowCount || 1,
          showSizeChanger: false,
        }}
        expandable={{
          expandedRowRender: (record) => (
            <List
              header={<strong>Toko/Alamat</strong>}
              bordered
              size="small"
              dataSource={record.address || []}
              renderItem={(item) => (
                <List.Item key={item.id}>
                  [{item?.name || ''}] <strong>{item.address}</strong>
                </List.Item>
              )}
            />
          ),
        }}
      />
      <AddCustomer
        visible={visible}
        onCreate={handleCreateUser}
        onCancel={() => setVisible(false)}
      />
    </>
  );
};

export default Customer;
