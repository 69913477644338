import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Row,
  Card,
  Col,
  Table,
  PageHeader,
  Button,
  Form,
  Input,
  Switch,
  Select,
  Typography,
  Popover,
} from 'antd';
import {
  PlusCircleOutlined,
  FilterOutlined,
  SortAscendingOutlined,
} from '@ant-design/icons';

import {
  useCategories,
  useProducts,
  useProductSum,
  useProductQty,
} from 'libs/hooks/products';
import { useQuery } from 'libs/utils/route';
import { columns } from 'pages/product/utils';
import { countQuantity } from 'libs/utils/number';

const { Option } = Select;
const { Title } = Typography;

const Product = () => {
  const history = useHistory();
  const query = useQuery();
  const [form] = Form.useForm();

  const [isFilterOpen, setFilterOpen] = useState(false);
  const [isSortOpen, setSortOpen] = useState(false);
  const [sortBy, setSortBy] = useState('');

  const { data: res, isLoading } = useProducts(query.toString());
  const { data: categories, onSearch } = useCategories();
  const { data: sum } = useProductSum(query.toString());
  const { data: totalQty } = useProductQty(query.toString());

  const onChangePage = (p) => {
    query.set('page', p.current);
    history.push(`/d/products/stock?${query.toString()}`);
  };

  const handleFilter = (value) => {
    if (value.name) query.set('name', value.name);
    if (value.category) query.set('category_id', value.category.value);
    query.set('is_barang_sortir', +value.is_barang_sortir);
    query.delete('page');

    history.push(`/d/products/stock?${query.toString()}`);
  };

  return (
    <>
      <PageHeader
        ghost={false}
        title="Stock Barang"
        extra={[
          <Button
            key={1}
            type="primary"
            onClick={() => history.push('/d/products/in')}
            icon={<PlusCircleOutlined />}
          >
            Tambah Barang
          </Button>,
          <Button
            key="filter"
            onClick={() => setFilterOpen(!isFilterOpen)}
            icon={<FilterOutlined />}
          >
            {isFilterOpen ? 'Sembunyikan Filter' : 'Filter'}
          </Button>,
          <Popover
            content={
              <>
                <Select
                  style={{ width: '100%' }}
                  value={sortBy}
                  onChange={(value) => setSortBy(value)}
                >
                  <Option value="">Terbaru</Option>
                  <Option value="created_at_asc">Terlama</Option>
                  <Option value="stock_qty_desc">Stok Terbanyak</Option>
                  <Option value="stock_qty_asc">Stok Paling Sedikit</Option>
                  <Option value="most_popular_desc">Paling Laku</Option>
                </Select>
                <div style={{ marginTop: 8 }}>
                  <Button
                    type="primary"
                    size="small"
                    style={{ marginRight: 8 }}
                    onClick={() => {
                      setSortOpen(false);
                      query.set('sortBy', sortBy);
                      history.push(`/d/products/stock?${query.toString()}`);
                    }}
                  >
                    Urutkan
                  </Button>
                  <Button
                    size="small"
                    onClick={() => {
                      setSortOpen(false);
                      query.delete('sortBy');
                      history.push(`/d/products/stock?${query.toString()}`);
                    }}
                  >
                    Reset
                  </Button>
                </div>
              </>
            }
            title="Urutkan"
            trigger="click"
            visible={isSortOpen}
            onVisibleChange={() => setSortOpen(!isSortOpen)}
          >
            <Button icon={<SortAscendingOutlined />}>Urutkan</Button>
          </Popover>,
        ]}
      />

      {isFilterOpen && (
        <Card>
          <Form layout="vertical" form={form} onFinish={handleFilter}>
            <Row gutter={[16]}>
              <Col span={6}>
                <Form.Item name="category" label="Model">
                  <Select
                    labelInValue
                    showSearch
                    placeholder="Ketik Nama Model"
                    onSearch={onSearch}
                    filterOption={false}
                  >
                    {categories?.data.map((category) => (
                      <Option key={category.id} value={category.id}>
                        {category.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Nama Barang" name="name">
                  <Input placeholder="Nama" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="Barang BS"
                  name="is_barang_sortir"
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ marginRight: 8 }}
                >
                  Terapkan
                </Button>

                <Button
                  type="primary"
                  htmlType="reset"
                  onClick={() => {
                    form.resetFields();
                    history.push('/d/products/stock');
                  }}
                  danger
                >
                  Reset
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      )}

      <Card style={{ marginTop: 16 }}>
        <div style={{ textAlign: 'right' }}>
          <Title level={5}>
            Total: {countQuantity(totalQty?.data?.total)} (Rp.{' '}
            {(sum?.data?.total || 0).toLocaleString('ID')})
          </Title>
        </div>
        <Table
          loading={isLoading}
          columns={columns}
          dataSource={res?.data}
          onChange={onChangePage}
          pagination={{
            current: +query.get('page') || 1,
            total: res?.pagination.rowCount || 1,
            showSizeChanger: false,
          }}
        />
      </Card>
    </>
  );
};

export default Product;
