import { countQuantity } from 'libs/utils/number';
import { getImage } from 'libs/utils/image';

export const columns = [
  {
    title: 'Nama',
    dataIndex: 'name',
    key: 'name',
    render: (name) => <span>{name}</span>,
  },
  {
    title: 'Gambar',
    dataIndex: 'picture',
    key: 'picture',
    render: (_, record) => {
      let picture = [];
      try {
        picture = JSON.parse(record.picture);
      } catch (error) {}

      return picture.length ? (
        <div style={{ maxWidth: 90, overflow: 'hidden', borderRadius: 4 }}>
          <img
            src={getImage(picture[0], 100)}
            alt="slider"
            style={{ width: '100%', height: '100%' }}
          />
        </div>
      ) : null;
    },
  },
  {
    title: 'Qty',
    dataIndex: 'total_qty',
    key: 'total_qty',
    render: (total_qty) => <span>{countQuantity(total_qty)}</span>,
  },
];
