import { format } from "date-fns";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Button, Col, Row } from "antd";

export const columns = [
  {
    title: "Invoice",
    dataIndex: "invoice_no",
    key: "invoice_no",
    render: (_, record) => (
      <div>
        <p style={{ fontSize: 13, margin: 0 }}>
          <strong>Customer:</strong> {record.customer_name}
        </p>
        <p style={{ fontSize: 12 }}>
          <strong>No Hp:</strong> {record.customer_phone}
        </p>
        <p style={{ fontSize: 11, fontWeight: 700 }}>{record.invoice_no}</p>
      </div>
    ),
  },
  {
    title: "Kasir",
    dataIndex: "cashier",
    key: "cashier",
    render: (cashier) => cashier?.name || "-",
  },
  {
    title: "Barang Sortir",
    dataIndex: "is_barang_sortir",
    key: "is_barang_sortir",
    render: (text) => (
      <div>
        {text ? (
          <CheckCircleOutlined style={{ color: "green" }} />
        ) : (
          <CloseCircleOutlined style={{ color: "red" }} />
        )}
      </div>
    ),
  },
  {
    title: "Discount",
    dataIndex: "discount",
    key: "discount",
    render: (value) => `Rp. ${value.toLocaleString("ID")}`,
  },
  {
    title: "Biaya Tambahan",
    dataIndex: "additional_cost",
    key: "additional_cost",
    render: (value) => `Rp. ${value.toLocaleString("ID")}`,
  },
  {
    title: "Total",
    dataIndex: "total",
    key: "total",
    render: (value) => `Rp. ${value.toLocaleString("id")}`,
  },
  {
    title: "Tanggal",
    dataIndex: "invoice_date",
    key: "invoice_date",
    render: (text) => format(new Date(text), "dd MMM yyyy"),
  },
  {
    title: "Aksi",
    dataIndex: "action",
    key: "action",
    render: (_, record) => (
      <Row gutter={16}>
        <Col>
          <Button
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              window.open(`/d/orders/${record.id}/invoice`);
            }}
          >
            Print Invoice
          </Button>
        </Col>
        <Col>
          <Button
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              window.open(`/d/orders/${record.id}/letter-of-shipping`);
            }}
          >
            Print Surat Jalan
          </Button>
        </Col>
      </Row>
    ),
  },
];
