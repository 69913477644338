import { format } from 'date-fns';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { countQuantity } from 'libs/utils/number';

export const columns = [
  {
    title: 'Aktor',
    dataIndex: 'actor',
    key: 'actor',
  },
  {
    title: 'Barang Sortir',
    dataIndex: 'is_barang_sortir',
    key: 'is_barang_sortir',
    render: (text) => (
      <div>
        {text ? (
          <CheckCircleOutlined style={{ color: 'green' }} />
        ) : (
          <CloseCircleOutlined style={{ color: 'red' }} />
        )}
      </div>
    ),
  },
  {
    title: 'Total Motif',
    dataIndex: 'items',
    key: 'items',
    render: (i) => `${i?.length || 0} motif`,
  },
  {
    title: 'Total Barang',
    dataIndex: 'items',
    key: 'items',
    render: (items) => {
      const t = items.reduce((x, y) => x + y.qty, 0);
      return countQuantity(t);
    },
  },
  {
    title: 'Total Harga',
    dataIndex: 'total',
    key: 'total',
    render: (total) => {
      return `Rp. ${total?.toLocaleString('ID')}`;
    },
  },
  {
    title: 'Ditambahkan',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (value) =>
      value ? format(new Date(value), 'dd MMM yyyy HH:mm') : '-',
  },
];
