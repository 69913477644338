import { Switch, Route } from 'react-router-dom';
import { Layout } from 'antd';

import { Sidebar } from 'components';

import Sales from 'pages/sales';
import Customer from 'pages/customer';
import Product from 'pages/product';
import ProductPopular from 'pages/product-popular';
import ProductIn from 'pages/product-in';
import ReceivalHistory from 'pages/receival-history';
import ReceivalDetail from 'pages/receival-detail';
import Order from 'pages/order';
import Invoice from 'pages/order-invoice';
import LetterOfShipping from 'pages/letter-of-shipping';
import OrderDetail from 'pages/order-detail';

const { Content } = Layout;

function App() {
  return (
    <Layout style={{ minHeight: '100vh', overflow: 'hidden' }}>
      <Sidebar />
      <Layout style={{ padding: '24px' }}>
        <Content>
          <Switch>
            <Route exact path="/d" component={Sales} />
            <Route exact path="/d/customers" component={Customer} />
            <Route exact path="/d/products/stock" component={Product} />
            <Route
              exact
              path="/d/products/popular"
              component={ProductPopular}
            />
            <Route exact path="/d/products/in" component={ProductIn} />
            <Route exact path="/d/receival" component={ReceivalHistory} />
            <Route exact path="/d/receival/:id" component={ReceivalDetail} />
            <Route exact path="/d/orders" component={Order} />
            <Route exact path="/d/orders/:id" component={OrderDetail} />
            <Route exact path="/d/orders/:id/invoice" component={Invoice} />
            <Route
              exact
              path="/d/orders/:id/letter-of-shipping"
              component={LetterOfShipping}
            />
          </Switch>
        </Content>
      </Layout>
    </Layout>
  );
}

export default App;
