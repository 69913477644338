import { useState } from 'react';
import useToast from 'libs/utils/toast';
import { useCallback } from 'react';
import useSWR, { mutate } from 'swr';
import debounce from 'lodash/debounce';

import api from 'libs/utils/api';

export const useUsers = (querystring) => {
  const toast = useToast();

  const pathKey = `/user?role=USER&${querystring}`;
  const { data, error, isValidating } = useSWR(pathKey);

  const onAdd = useCallback(
    async (payload) => {
      try {
        const userData = {
          name: payload.customerName,
          phone: payload.customerPhone,
          password: '1234',
        };

        const addressData = {
          name: payload.addressName || payload.customerName,
          receiver: payload.customerName,
          phone: payload.customerPhone,
          address: payload.customerAddress,
        };

        const { data: res } = await api.post('/user', {
          ...userData,
          ...(payload.customerAddress ? { address: addressData } : {}),
        });

        if (res.success) {
          mutate(pathKey);
          mutate('/user?role=USER&');
          toast('success', 'Customer berhasil ditambahkan.');

          return res?.data;
        } else {
          toast('error', 'Customer gagal ditambahkan.');
        }
      } catch (error) {
        toast('error', error.toString());
      }
    },
    [toast, pathKey]
  );

  const onFetchDetail = useCallback(async (userId) => {
    try {
      const detailUserPathKey = `/user/${userId}`;

      const { data: res } = await api.get(detailUserPathKey);

      if (res.success) {
        return res.data;
      }
    } catch (error) {
      toast('error', error.toString());
    }
  }, [toast]);

  return {
    data: data,
    isLoading: (!error && !data?.data) || isValidating,
    isError: error,
    onAdd,
    onFetchDetail
  };
};

export const useCustomer = () => {
  const [name, setName] = useState('');
  const pathKey = `/user?role=USER&name=${name}&page=1&limit=6`;
  const { data, error, isValidating } = useSWR(name ? pathKey : null);

  const onSearch = debounce(
    useCallback((value) => {
      setName(value);
    }, []),
    300
  );

  return {
    data: data,
    isLoading: (!error && !data?.data) || isValidating,
    isError: error,
    onSearch,
  };
};

export const useOptions = () => {
  const pathKey = `/option`;
  const { data, error, isValidating } = useSWR(pathKey);

  return {
    data: data,
    isLoading: (!error && !data?.data) || isValidating,
    isError: error,
  };
};

export const useBankAccount = () => {
  const pathKey = `/bank`;
  const { data, error, isValidating } = useSWR(pathKey);

  return {
    data: data,
    isLoading: (!error && !data?.data) || isValidating,
    isError: error,
  };
};
