import { useCallback, useState, useRef } from 'react';
import format from 'date-fns/format';
import {
  PageHeader,
  Card,
  Col,
  Row,
  Form,
  Switch,
  Table,
  Button,
  Typography,
  Empty,
  Modal,
  Badge,
} from 'antd';
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons';

import { SearchWHProduct, ProductCard, Quantifier } from 'components';
import { useCarts } from 'libs/hooks/cart';
import { useProductReceivals } from 'libs/hooks/products';
import { columns } from 'pages/product-in/utils';

const { Title } = Typography;
const { confirm } = Modal;

const ProductIn = () => {
  const [products, setProducts] = useState([]);
  const [selected, setSelected] = useState();
  const [isBS, setBS] = useState(false);
  const [row, setRow] = useState();
  const [sku, setSku] = useState();
  const getScanBarcode = window.localStorage.getItem('isScanBarcode');
  const [isScanBarcode, setScanBarcode] = useState(getScanBarcode === '1');
  const [isFocus, setFocus] = useState(false);
  const inputSKU = useRef();

  const {
    data: carts,
    loading,
    onAdd,
    onEdit,
    onDelete,
    onAddProductInBySKU,
  } = useCarts('IN');
  const { onAdd: submit } = useProductReceivals();

  const total = (carts?.data || []).reduce((t, { product, qty }) => {
    const price = product.on_sale
      ? product.price - (product.sale_price / 100) * product.price
      : product.price;

    t += price * qty;
    return t;
  }, 0);

  const handleSubmit = useCallback(() => {
    submit({
      invDate: format(new Date(), 'yyyy-MM-dd'),
      isBS,
    });
  }, [isBS, submit]);

  return (
    <>
      <PageHeader
        ghost={false}
        title="Barang Masuk"
        style={{ marginBottom: 16 }}
        extra={[
          <Button
            key="save"
            type="primary"
            icon={<SaveOutlined />}
            onClick={handleSubmit}
          >
            Simpan
          </Button>,
          <Button
            key="reset"
            type="primary"
            danger
            icon={<DeleteOutlined />}
            onClick={() =>
              confirm({
                title: 'Hapus Item',
                content:
                  'Apakah anda yakin ingin menghapus semua item di barang masuk?',
                onOk: () => onDelete((carts?.data || []).map((c) => c.id)),
              })
            }
          >
            Reset
          </Button>,
        ]}
      />
      <Row gutter={[16, 16]}>
        <Col span={12}>
          {isScanBarcode ? (
            <Card
              title="Scanner"
              size="small"
              style={{ height: '100%' }}
              extra={
                <Button
                  onClick={() => {
                    setScanBarcode(false);
                    window.localStorage.setItem('isScanBarcode', 0);
                  }}
                >
                  Cari Manual
                </Button>
              }
            >
              <input
                ref={inputSKU}
                autoFocus
                style={{ opacity: 0, width: 0 }}
                value={sku}
                onChange={(e) => setSku(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onAddProductInBySKU(sku);
                    setSku('');
                  }
                }}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
              />
              <Badge
                color={isFocus ? 'green' : 'red'}
                text={
                  isFocus ? (
                    <Button disabled>Connected</Button>
                  ) : (
                    <Button onClick={() => inputSKU.current.focus()}>
                      Reconnect
                    </Button>
                  )
                }
              />
            </Card>
          ) : (
            <Card
              title="Cari Barang"
              size="small"
              style={{ height: '100%' }}
              extra={
                <Button
                  onClick={() => {
                    setScanBarcode(true);
                    window.localStorage.setItem('isScanBarcode', 1);
                  }}
                >
                  Gunakan Scanner
                </Button>
              }
            >
              <Form layout="vertical" style={{ paddingRight: 16 }}>
                <Form.Item name="name">
                  <SearchWHProduct
                    placeholder="Ketik nama barang disini..."
                    onSearch={(result) => setProducts(result)}
                  />
                </Form.Item>
              </Form>
              <div
                style={{
                  flexDirection: 'column',
                  overflow: 'auto',
                  height: '100%',
                  maxHeight: '540px',
                  marginTop: 16,
                }}
              >
                <Row gutter={[16, 16]}>
                  {!products.length ? (
                    <Col offset={8} span={8}>
                      <Empty description="Barang belum ditemukan. Cari kata kunci lainnya" />
                    </Col>
                  ) : null}
                  {products.map((p, idx) => (
                    <Col key={idx} xs={24} sm={24} md={12} lg={12} xl={8}>
                      <ProductCard {...p} onSelect={() => setSelected(p.id)} />
                    </Col>
                  ))}
                </Row>
              </div>
              <Quantifier
                onSubmit={(qty) => {
                  onAdd({
                    productID: selected,
                    qty,
                  });
                  setSelected(null);
                }}
                visible={!!selected}
                onCancel={() => setSelected(null)}
              />
            </Card>
          )}
        </Col>
        <Col span={12}>
          <Card title="Detail" size="small" style={{ height: '100%' }}>
            <Switch checked={isBS} onChange={(value) => setBS(value)} /> Barang
            BS
          </Card>
        </Col>
      </Row>

      <Table
        style={{ marginTop: 16 }}
        columns={columns(onDelete, setRow)}
        loading={loading}
        dataSource={carts?.data.map((c) => ({ key: c.id, ...c }))}
        size="small"
        title={() => (
          <Row>
            <Col span={12}>
              <Title level={5}>Daftar Item</Title>
            </Col>
            <Col span={12}>
              <Title style={{ textAlign: 'right' }} level={5}>
                Total: Rp. {total.toLocaleString('ID')}
              </Title>
            </Col>
          </Row>
        )}
      />
      {row ? (
        <Quantifier
          onSubmit={(qty) => {
            onEdit(row.id, qty);
            setRow(null);
          }}
          visible={!!row}
          initialValue={row.qty}
          onCancel={() => setRow(null)}
        />
      ) : null}
    </>
  );
};

export default ProductIn;
