import { format } from 'date-fns';

export const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Telp',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: 'Ditambahkan',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (value) =>
      value ? format(new Date(value), 'dd MMM yyyy HH:mm') : '-',
  },
];
