import { format } from 'date-fns';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { getImage } from 'libs/utils/image';
import { countQuantity } from 'libs/utils/number';

export const columns = [
  {
    title: 'Nama',
    dataIndex: 'name',
    key: 'name',
    render: (_, record) => (
      <>
        <h3>{record.detail.name}</h3>
        <p style={{ fontSize: 12 }}>SKU: {record.detail.sku}</p>
        <p style={{ fontSize: 12 }}>Model: {record.detail.category.name}</p>
      </>
    ),
  },
  {
    title: 'Gambar',
    dataIndex: 'pictures',
    key: 'pictures',
    render: (_, record) => {
      let pictures = [];
      try {
        pictures = JSON.parse(record.detail.pictures);
      } catch (error) {}

      return pictures.length ? (
        <div style={{ maxWidth: 90, overflow: 'hidden', borderRadius: 4 }}>
          <img
            src={getImage(pictures[0], 100)}
            alt="slider"
            style={{ width: '100%', height: '100%' }}
          />
        </div>
      ) : null;
    },
  },
  {
    title: 'Stock',
    dataIndex: 'stock_qty',
    key: 'stock_qty',
    render: (text) => (text ? <span>{countQuantity(text)}</span> : 'Kosong'),
  },
  {
    title: 'Barang BS',
    dataIndex: 'is_barang_sortir',
    key: 'is_barang_sortir',
    render: (text) => (
      <div>
        {text ? (
          <CheckCircleOutlined style={{ color: 'green' }} />
        ) : (
          <CloseCircleOutlined style={{ color: 'red' }} />
        )}
      </div>
    ),
  },
  {
    title: 'Harga',
    dataIndex: 'price',
    key: 'price',
    render: (_, record) => {
      return record.detail.on_sale ? (
        <>
          <span
            style={{ textDecoration: 'line-through' }}
          >{`Rp. ${record.detail.price.toLocaleString('ID')} `}</span>
          <span style={{ color: 'red' }}>{`Rp. ${(
            record.detail.price -
            (record.detail.sale_price / 100) * record.detail.price
          ).toLocaleString('ID')}`}</span>
        </>
      ) : (
        `Rp. ${record.detail.price.toLocaleString('ID')}`
      );
    },
  },
  {
    title: 'Total Stok Rupiah',
    dataIndex: 'price',
    key: 'price',
    render: (_, record) => {
      const price = record.detail.on_sale
        ? record.detail.price -
          (record.detail.sale_price / 100) * record.detail.price
        : record.detail.price;
      const stockInRp = price * record.stock_qty;
      return (
        <span
          style={{ color: stockInRp < 0 ? 'red' : 'black' }}
        >{`Rp. ${stockInRp.toLocaleString('ID')}`}</span>
      );
    },
  },
  {
    title: 'Terakhir Diupdate',
    dataIndex: 'updated_at',
    key: 'updated_at',
    render: (value) =>
      value ? format(new Date(value), 'dd MMM yyyy HH:mm') : '-',
  },
];
