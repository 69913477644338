import { notification } from 'antd';

const useToast = () => {
  const title = {
    success: 'Sukses!',
    error: 'Oops!',
  };

  return (status, message) =>
    notification[status]({
      message: title[status],
      description: message,
      placement: 'bottomLeft',
    });
};

export default useToast;
