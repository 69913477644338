import get from 'lodash/get';
import chunk from 'lodash/chunk';
import {
  PDFViewer,
  Page,
  Text,
  View,
  StyleSheet,
  Document,
} from '@react-pdf/renderer';
import format from 'date-fns/format';

import { useOptions } from 'libs/hooks/users';
import { useOrderDetail } from 'libs/hooks/order';

import { countQuantity } from 'libs/utils/number';

const styles = StyleSheet.create({
  viewer: {
    width: '100%',
    height: '100vh',
    margin: -24,
    padding: 0,
    position: 'fixed',
    border: 'none',
  },
  header: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginVertical: 8,
    marginHorizontal: 16,
    fontSize: 11,
  },
  tableHead: {
    marginVertical: 4,
    borderTop: 1,
    borderBottom: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    paddingVertical: 4,
    paddingHorizontal: 16,
    fontSize: 11,
  },
  tableRow: {
    padding: '0 16pt',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    fontSize: 11,
  },
  tableCell: {
    width: '20%',
    fontSize: 11,
  },
  footer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginHorizontal: 16,
    marginTop: 'auto',
    fontSize: 11,
    borderTop: 1,
    paddingVertical: 8,
  },
});

const groupByCategory = (items) => {
  const categories = items.reduce((dict, item) => {
    const product = item.product.detail;
    const key = `${product.category_id}_${product.sale_price}`;
    const name =
      product.sale_price > 0
        ? product.category.name + ' (disc ' + product.sale_price + '%)'
        : product.category.name;
    if (dict.hasOwnProperty(key)) {
      dict[key].push({
        ...item,
        categoryName: name,
      });
    } else {
      dict[key] = [{ ...item, categoryName: name }];
    }

    return dict;
  }, {});

  const sumItemGroup = Object.keys(categories).map((catID) => {
    const subtotal = categories[catID].reduce((total, item) => {
      total += item.subtotal;
      return total;
    }, 0);

    const quantity = categories[catID].reduce((total, item) => {
      total += item.quantity;
      return total;
    }, 0);

    return {
      ...categories[catID][0],
      quantity,
      subtotal,
    };
  });

  return sumItemGroup.map((s, idx) => ({ ...s, no: idx + 1 }));
};

const tableWidthMapper = {
  NO: '10%',
  BANYAK: '20%',
  'NAMA BARANG': '30%',
  HARGA: '15%',
  JUMLAH: '25%',
};

const LetterOfShipping = ({ match }) => {
  const {
    params: { id },
  } = match;

  const { data: options } = useOptions();
  const { data: order } = useOrderDetail(id);

  let mappedOptions;

  if (options?.data.length !== 0) {
    let split;
    mappedOptions = options?.data.map((option) => {
      split = {
        ...split,
        [option.key]: option.value,
      };

      return split;
    });

    if (mappedOptions) {
      let mappedOptionsLength = mappedOptions.length;
      mappedOptions = mappedOptions[mappedOptionsLength - 1];
    }
  }

  return (
    <PDFViewer style={styles.viewer}>
      <Document>
        {order &&
          chunk(groupByCategory(order?.data.items), 18).map((items, idx) => (
            <Page size="A5" key={idx} orientation="landscape">
              <View style={styles.header}>
                <View>
                  <Text style={{ marginBottom: 2 }}>HELLO Collection</Text>
                  <Text style={{ marginBottom: 2 }}>
                    PRODUCT PAKAIAN WANITA
                  </Text>
                  {mappedOptions && (
                    <>
                      <Text style={{ marginBottom: 2 }}>
                        {mappedOptions.address}
                      </Text>
                      <Text style={{ marginBottom: 2 }}>
                        Telp: {mappedOptions.office_phone}
                      </Text>
                      <Text style={{ marginBottom: 2 }}>
                        HP: {mappedOptions.office_phone} WA:{' '}
                        {mappedOptions.whatsapp_number}
                      </Text>
                    </>
                  )}
                </View>
                <View>
                  <Text style={{ marginBottom: 2 }}>
                    No: {order?.data.invoice_no}
                  </Text>
                  <Text style={{ marginBottom: 2 }}>
                    Jakarta, {format(new Date(order?.data.created_at), 'dd/MM/yyyy')}
                  </Text>
                  <Text style={{ marginBottom: 2 }}>
                    {get(order?.data, 'customer_receiver_name', '')}
                  </Text>
                  <Text style={{ marginBottom: 2 }}>
                    {get(order?.data, 'customer_address', 'Alamat: -')}
                  </Text>
                </View>
              </View>
              <View style={styles.tableHead}>
                {['NO', 'BANYAK', 'NAMA BARANG'].map((x) => (
                  <View
                    key={x}
                    style={[
                      styles.tableCell,
                      {
                        width: tableWidthMapper[x],
                        textAlign: 'left',
                      },
                    ]}
                  >
                    <Text>{x}</Text>
                  </View>
                ))}
              </View>
              <View>
                {items.map((item, idx) => (
                  <View key={idx} style={styles.tableRow}>
                    <View
                      style={[styles.tableCell, { width: tableWidthMapper.NO }]}
                    >
                      <Text>{item.no}</Text>
                    </View>
                    <View
                      style={[
                        styles.tableCell,
                        { width: tableWidthMapper.BANYAK },
                      ]}
                    >
                      <Text>{countQuantity(item.quantity)}</Text>
                    </View>
                    <View
                      style={[
                        styles.tableCell,
                        { width: tableWidthMapper['NAMA BARANG'] },
                      ]}
                    >
                      <Text>{item.categoryName}</Text>
                    </View>
                  </View>
                ))}
              </View>
            </Page>
          ))}
      </Document>
    </PDFViewer>
  );
};

export default LetterOfShipping;
