import { Route, Redirect } from 'react-router-dom';
import { useCookies } from 'react-cookie';

function PrivateRoute({ component: Component, ...rest }) {
  const [cookies] = useCookies();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (cookies?.accessToken) {
          return <Component {...props} />;
        }

        return <Redirect to={{ pathname: '/' }} />;
      }}
    />
  );
}

export default PrivateRoute;
