import useSWR, { mutate } from 'swr';
import { createContext, useState, useContext, useCallback } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';

import api from 'libs/utils/api';
import useToast from 'libs/utils/toast';

const AuthContext = createContext({
  loading: false,
  isAuthenticated: false,
  user: null,
  authenticate: () => {},
  logout: () => {},
});

export const AuthProvider = ({ children }) => {
  const history = useHistory();
  const [cookies, setCookie, removeCookie] = useCookies();
  const { data: res } = useSWR(() =>
    cookies?.accessToken ? '/profile' : null
  );

  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const authenticate = useCallback(
    async (data) => {
      setLoading(true);
      try {
        const { data: res } = await api.post('/login', data);
        let userRole = res.user.role.toLowerCase();

        if (res.token && userRole !== 'user') {
          setCookie('accessToken', res.token);

          setTimeout(() => {
            history.push('/d');

            mutate('/profile');
          }, 500);
        } else {
          toast('error', 'Gagal ketika mencoba masuk.');
        }

      } catch (error) {
        toast('error', 'Kesalahan pada sistem. ' + error);
      } finally {
        setLoading(false);
      }
    },
    [history, setCookie, toast]
  );

  const logout = useCallback(() => {
    removeCookie('accessToken');
    delete api.defaults.headers.Authorization;
    history.replace('/');
  }, [history, removeCookie]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: !!res?.data,
        user: res?.data,
        authenticate,
        loading,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
