/* eslint-disable react-hooks/exhaustive-deps */
import { useHistory } from 'react-router-dom';
import { Table, PageHeader, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { useProductReceivals } from 'libs/hooks/products';
import { useQuery } from 'libs/utils/route';
import { columns } from 'pages/receival-history/utils';

const ReceivalHistory = () => {
  const history = useHistory();
  const query = useQuery();

  const { data: res, isLoading } = useProductReceivals(query.toString());

  const onChangePage = (p) => {
    query.set('page', p.current);
    history.push(`/d/receival?${query.toString()}`);
  };

  return (
    <>
      <PageHeader
        ghost={false}
        title="History Barang Masuk"
        extra={[
          <Button
            key={1}
            type="primary"
            onClick={() => history.push('/d/receival')}
            icon={<PlusOutlined />}
          >
            Barang Masuk
          </Button>,
        ]}
      />

      <Table
        style={{ marginTop: 16 }}
        loading={isLoading}
        columns={columns}
        dataSource={(res?.data || []).map((d) => ({ key: d.id, ...d }))}
        onChange={onChangePage}
        pagination={{
          current: +query.get('page') || 1,
          total: res?.pagination?.rowCount || 1,
          showSizeChanger: false,
        }}
        onRow={(record) => ({
          onClick: () => {
            history.push(`/d/receival/${record.id}`);
          },
        })}
      />
    </>
  );
};

export default ReceivalHistory;
