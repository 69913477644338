import React from 'react';
import ReactDOM from 'react-dom';
import { SWRConfig } from 'swr';
import { CookiesProvider } from 'react-cookie';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import App from 'App';
import { PrivateRoute, ScrollToTop } from 'components';
import Login from 'pages/login';
import { AuthProvider } from 'libs/contexts/auth';
import reportWebVitals from 'reportWebVitals';
import { fetcher } from 'libs/utils/api';

import 'index.css';

ReactDOM.render(
  <CookiesProvider>
    <SWRConfig
      value={{
        refreshInterval: 0,
        fetcher,
      }}
    >
      <Router>
        <ScrollToTop />
        <AuthProvider>
          <Switch>
            <Route path="/" exact component={Login} />
            <PrivateRoute path="/d" component={App} />
            <Route path="*">
              <Redirect to="/404" />
            </Route>
          </Switch>
        </AuthProvider>
      </Router>
    </SWRConfig>
  </CookiesProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
