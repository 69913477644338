import { useState } from 'react';
import { Layout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import {
  ShoppingCartOutlined,
  LogoutOutlined,
  ApartmentOutlined,
  UserOutlined,
} from '@ant-design/icons';

import { useAuth } from 'libs/contexts/auth';

const { Sider } = Layout;
const { SubMenu } = Menu;

const hideMenu = [
  /(\/orders)\/\d+\/(invoice)$/,
  /(\/orders)\/\d+\/(letter-of-shipping)$/,
];

const Sidebar = () => {
  const location = useLocation();
  const { logout } = useAuth();

  const isCollapsed =
    window.localStorage.getItem('sidebar_collapse') === '1' || '0';
  const [collapsed, setCollapsed] = useState(+isCollapsed);

  const hideSidebar = hideMenu.some((x) => x.test(location.pathname));

  if (hideSidebar) {
    return null;
  }

  return (
    <Sider
      width={200}
      theme="dark"
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => {
        setCollapsed(value);
        window.localStorage.setItem('sidebar_collapse', +value);
      }}
    >
      <div
        style={{
          height: 32,
          background: 'rgba(255, 255, 255, 0.2)',
          margin: 16,
          textAlign: 'center',
          color: '#FFF',
          paddingTop: 4,
          fontWeight: 700,
        }}
      >
        {collapsed ? 'HP' : 'HELLOPGMTA'}
      </div>
      <Menu
        mode="inline"
        theme="dark"
        style={{ height: '100%', borderRight: 0 }}
        selectedKeys={[location.pathname]}
      >
        <SubMenu key="/d" icon={<ShoppingCartOutlined />} title="Penjualan">
          <Menu.Item key="/d">
            <Link to="/d">Pesanan Baru</Link>
          </Menu.Item>
          <Menu.Item key="/d/orders">
            <Link to="/d/orders">History Pesanan</Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu key="/d/products" icon={<ApartmentOutlined />} title="Barang">
          <Menu.Item key="/d/products/in">
            <Link to="/d/products/in">Barang Masuk</Link>
          </Menu.Item>
          <Menu.Item key="/d/receival">
            <Link to="/d/receival">History Barang Masuk</Link>
          </Menu.Item>
          <Menu.Item key="/d/products/stock">
            <Link to="/d/products/stock">Stock Barang</Link>
          </Menu.Item>
          <Menu.Item key="/d/orders">
            <Link to="/d/products/popular">Produk Terlaris</Link>
          </Menu.Item>
        </SubMenu>
        <Menu.Item key="/d/customers" icon={<UserOutlined />}>
          <span>Customer</span>
          <Link to="/d/customers" />
        </Menu.Item>
        <Menu.Item
          danger
          key="/logout"
          onClick={logout}
          icon={<LogoutOutlined />}
        >
          Logout
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default Sidebar;
