/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Table,
  PageHeader,
  Button,
  Form,
  Card,
  Row,
  Select,
  Col,
  DatePicker,
  Descriptions,
} from 'antd';
import { PlusOutlined, FilterOutlined } from '@ant-design/icons';

import { useCustomer } from 'libs/hooks/users';
import { useOrder } from 'libs/hooks/order';
import { useQuery } from 'libs/utils/route';
import { columns } from 'pages/order/utils';

const { Option } = Select;
const { RangePicker } = DatePicker;

const OrderHistory = () => {
  const history = useHistory();
  const query = useQuery();
  const [form] = Form.useForm();

  const [isFilterOpen, setFilterOpen] = useState(false);
  const { data: customers, onSearch } = useCustomer();
  const { data: res, isLoading } = useOrder(query.toString());

  const onChangePage = (p) => {
    query.set('page', p.current);
    history.push(`/d/orders?${query.toString()}`);
  };

  const handleFilter = (value) => {
    if (value.date_range) {
      query.set('start_date', value.date_range[0].format('YYYY-MM-DD'));
      query.set('end_date', value.date_range[1].format('YYYY-MM-DD'));
    } else {
      query.delete('start_date');
      query.delete('end_date');
    }

    if (value.customer_id) {
      query.set('customer_id', value.customer_id);
    } else {
      query.delete('customer_id');
    }

    if (value.is_invoice_barang_sortir !== '') {
      query.set('is_invoice_barang_sortir', +value.is_invoice_barang_sortir);
    } else {
      query.delete('is_invoice_barang_sortir');
    }

    query.delete('page');

    history.push(`/d/orders?${query.toString()}`);
  };

  return (
    <>
      <PageHeader
        ghost={false}
        title="History Pesanan"
        extra={[
          <Button
            key={1}
            type="primary"
            onClick={() => history.push('/d')}
            icon={<PlusOutlined />}
          >
            Pesanan Baru
          </Button>,
          <Button
            key="filter"
            onClick={() => setFilterOpen(!isFilterOpen)}
            icon={<FilterOutlined />}
          >
            {isFilterOpen ? 'Sembunyikan Filter' : 'Filter'}
          </Button>,
        ]}
      />

      {isFilterOpen && (
        <Card>
          <Form layout="vertical" form={form} onFinish={handleFilter}>
            <Row gutter={[16]}>
              <Col span={8}>
                <Form.Item label="Tanggal" name="date_range">
                  <RangePicker />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Nama Customer" name="customer_id">
                  <Select
                    showSearch
                    placeholder="Ketik nama customer..."
                    optionFilterProp="children"
                    style={{ width: '100%', maxWidth: '100%' }}
                    onSearch={onSearch}
                  >
                    <Option key={-1} value="">
                      -- Pilih Customer --
                    </Option>
                    {(customers?.data || []).map((c) => {
                      return (
                        <Option key={c.id} value={c.id}>
                          {c.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Barang BS" name="is_invoice_barang_sortir">
                  <Select
                    placeholder="Pilih..."
                    style={{ width: '100%', maxWidth: '100%' }}
                  >
                    <Option key={-1} value="">
                      -- Pilih Tipe --
                    </Option>
                    <Option value="1">Barang BS</Option>
                    <Option value="0">Bukan Barang BS</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ marginRight: 8 }}
                >
                  Terapkan
                </Button>

                <Button
                  type="primary"
                  htmlType="reset"
                  onClick={() => {
                    form.resetFields();
                    history.push('/d/orders');
                  }}
                  danger
                >
                  Reset
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      )}

      <Table
        style={{ marginTop: 16 }}
        loading={isLoading}
        columns={columns}
        dataSource={(res?.data || []).map((d) => ({ key: d.id, ...d }))}
        onChange={onChangePage}
        onRow={(record) => ({
          onClick: () => {
            history.push(`/d/orders/${record.id}`);
          },
        })}
        pagination={{
          current: +query.get('page') || 1,
          total: res?.pagination.rowCount || 1,
          showSizeChanger: false,
        }}
        title={() => (
          <Row gutter={[16]}>
            <Col span={8} offset={16}>
              <Descriptions bordered size="small">
                <Descriptions.Item label="Total: ">
                  Rp. {res?.pagination.sum_total.toLocaleString('id')}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
        )}
      />
    </>
  );
};

export default OrderHistory;
