/* eslint-disable react-hooks/exhaustive-deps */

import useSWR from 'swr';
import debounce from 'lodash/debounce';
import { useState, useEffect } from 'react';
import { Input } from 'antd';

const SearchPOSProduct = ({ placeholder, onSearch }) => {
  const [query, setQuery] = useState('');
  const { data: res, error, isValidating } = useSWR(() =>
    query ? '/pos-product?limit=100&name=' + query : null
  );

  useEffect(() => {
    const isLoading = (!error && !res?.data) || isValidating;
    if (query && !isLoading) {
      onSearch(res.data);
    } else {
      onSearch([]);
    }
  }, [query, error, isValidating]);

  return (
    <Input.Search
      allowClear
      placeholder={placeholder}
      onChange={debounce(({ target }) => setQuery(target.value), 300)}
    />
  );
};

export default SearchPOSProduct;
