import { Button, Modal } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { countQuantity } from 'libs/utils/number';

const { confirm } = Modal;

export const columns = (onDelete, setRow) => [
  {
    title: 'Nama',
    dataIndex: 'name',
    key: 'name',
    render: (_, { product }) => <span>{product.name}</span>,
  },
  {
    title: 'Harga',
    dataIndex: 'price',
    key: 'price',
    render: (_, { product }) => {
      return product.on_sale ? (
        <>
          <span
            style={{ textDecoration: 'line-through' }}
          >{`Rp. ${product.price.toLocaleString('ID')} `}</span>
          <span style={{ color: 'red' }}>{`Rp. ${(
            product.price -
            (product.sale_price / 100) * product.price
          ).toLocaleString('ID')}`}</span>
        </>
      ) : (
        `Rp. ${product.price.toLocaleString('ID')}`
      );
    },
  },
  {
    title: 'Qty',
    dataIndex: 'qty',
    key: 'qty',
    render: (_, record) => <span>{countQuantity(record.qty)}</span>,
  },
  {
    title: 'Subtotal',
    render: (_, { product, qty }) => {
      const price = product.on_sale
        ? product.price - (product.sale_price / 100) * product.price
        : product.price;

      return <span>Rp. {(price * (qty || 0)).toLocaleString('ID')}</span>;
    },
  },
  {
    title: 'Qty',
    dataIndex: 'qty',
    key: 'qty',
    render: (_, record) => <span>{countQuantity(record.qty)}</span>,
  },
  {
    title: 'Hapus',
    dataIndex: 'id',
    key: 'id',
    render: (id, record) => {
      return (
        <>
          <Button type="link" onClick={() => setRow(record)}>
            <EditOutlined />
          </Button>
          <Button
            type="link"
            danger
            onClick={() =>
              confirm({
                title: 'Hapus Item',
                content: 'Apakah anda yakin ingin menghapus item ini?',
                onOk: () => onDelete(id),
              })
            }
          >
            <DeleteOutlined />
          </Button>
        </>
      );
    },
  },
];
