import { Modal, Form, InputNumber } from 'antd';
import { pcsToDozen } from 'libs/utils/number';

const Quantifier = ({ visible, onSubmit, onCancel, initialValue }) => {
  const [form] = Form.useForm();
  const { dozen, pcs } = pcsToDozen(initialValue);

  return (
    <Modal
      destroyOnClose
      visible={visible}
      title="Masukkan Jumlah"
      okText="Tambah"
      cancelText="Batal"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            const dozen = (values.dozen || 0) * 12;
            onSubmit(dozen + (values?.piece || 0));

            form.resetFields();
          })
          .catch((info) => {
            console.error('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        initialValues={{ dozen: dozen || 0, piece: pcs || 0 }}
        layout="vertical"
        name="form_in_modal"
      >
        <Form.Item name="dozen" label="Lusin">
          <InputNumber autoFocus size="large" style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item name="piece" label="Pcs">
          <InputNumber size="large" style={{ width: '100%' }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Quantifier;
