import React, { useEffect, useState } from "react";
import chunk from "lodash/chunk";
import {
  PDFViewer,
  Page,
  Text,
  View,
  StyleSheet,
  Document,
} from "@react-pdf/renderer";
import format from "date-fns/format";

import { useBankAccount, useOptions, useUsers } from "libs/hooks/users";
import { useOrderDetail } from "libs/hooks/order";

import { countQuantity, countUsedLoyaltyPoint } from "libs/utils/number";

const styles = StyleSheet.create({
  viewer: {
    width: "100%",
    height: "100vh",
    margin: -24,
    padding: 0,
    position: "fixed",
    border: "none",
  },
  header: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginVertical: 8,
    marginHorizontal: 16,
    fontSize: 11,
  },
  tableHead: {
    marginVertical: 4,
    borderTop: 1,
    borderBottom: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    paddingVertical: 4,
    paddingHorizontal: 16,
    fontSize: 11,
  },
  tableRow: {
    padding: "0 16pt",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    fontSize: 11,
  },
  tableCellNo: {
    fontSize: 11,
  },
  tableCell: {
    fontSize: 11,
  },
  tableCellPrice: {
    fontSize: 11,
  },
  footer: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginHorizontal: 16,
    marginTop: "auto",
    fontSize: 11,
    borderTop: 1,
    paddingVertical: 8,
  },
});

const groupByCategory = (items) => {
  const categories = items.reduce((dict, item) => {
    const key = `${item.product.detail.category_id}_${item.product.detail.price}_${item.product.detail.sale_price}`;
    const name =
      item.product.detail.sale_price > 0
        ? item.product.detail.category.name +
          " (disc " +
          item.product.detail.sale_price +
          "%)"
        : item.product.detail.category.name;
    if (dict.hasOwnProperty(key)) {
      dict[key].push({
        ...item,
        categoryName: name,
      });
    } else {
      dict[key] = [{ ...item, categoryName: name }];
    }

    return dict;
  }, {});

  const sumItemGroup = Object.keys(categories).map((catID) => {
    const subtotal = categories[catID].reduce((total, item) => {
      total += item.subtotal;
      return total;
    }, 0);

    const quantity = categories[catID].reduce((total, item) => {
      total += item.quantity;
      return total;
    }, 0);

    return {
      ...categories[catID][0],
      quantity,
      subtotal,
    };
  });

  return sumItemGroup.map((s, idx) => ({ ...s, no: idx + 1 }));
};

const tableWidthMapper = {
  NO: "5%",
  BANYAK: "15%",
  "NAMA BARANG": "30%",
  HARGA: "25%",
  JUMLAH: "25%",
};

const Invoice = ({ match }) => {
  const {
    params: { id },
  } = match;

  const { data: options } = useOptions();
  const { data: banks } = useBankAccount();
  const { data: order } = useOrderDetail(id);
  const { onFetchDetail: onFetchUserDetail } = useUsers();

  const [customer, setCustomer] = useState(null);

  useEffect(() => {
    if (order) {
      onFetchUserDetail(order?.data.customer_id).then((res) =>
        setCustomer(res)
      );
    }
    // eslint-disable-next-line
  }, [order]);

  let mappedOptions;

  if (options?.data?.length !== 0) {
    let split;
    mappedOptions = options?.data.map((option) => {
      split = {
        ...split,
        [option.key]: option.value,
      };

      return split;
    });

    if (mappedOptions) {
      let mappedOptionsLength = mappedOptions.length;
      mappedOptions = mappedOptions[mappedOptionsLength - 1];
    }
  }

  const discPct = (
    (order?.data.discount / order?.data.total_before) *
    100
  ).toFixed(2);

  return (
    <PDFViewer style={styles.viewer}>
      <Document>
        {order?.data &&
          banks?.data &&
          customer &&
          chunk(groupByCategory(order?.data.items), 18).map((items, idx) => (
            <Page size="A5" key={idx} orientation="landscape">
              <View style={styles.header}>
                <View>
                  <Text style={{ marginBottom: 2 }}>HELLO Collection</Text>
                  <Text style={{ marginBottom: 2 }}>
                    PRODUCT PAKAIAN WANITA
                  </Text>
                  {mappedOptions && (
                    <>
                      <Text style={{ marginBottom: 2 }}>
                        {mappedOptions.address}
                      </Text>
                      <Text style={{ marginBottom: 2 }}>
                        Telp: {mappedOptions.office_phone}
                      </Text>
                      <Text style={{ marginBottom: 2 }}>
                        HP: {mappedOptions.office_phone} WA:{" "}
                        {mappedOptions.whatsapp_number}
                      </Text>
                    </>
                  )}
                </View>
                <View>
                  <Text style={{ marginBottom: 2 }}>
                    No: {order?.data.invoice_no}
                  </Text>
                  <Text style={{ marginBottom: 2 }}>
                    Jakarta,{" "}
                    {format(new Date(order?.data.created_at), "dd/MM/yyyy")}
                  </Text>
                  <Text style={{ marginBottom: 2 }}>
                    Kepada Yth: {customer?.name}
                  </Text>
                </View>
              </View>
              <View style={styles.tableHead}>
                {["NO", "BANYAK", "NAMA BARANG", "HARGA", "JUMLAH"].map((x) => (
                  <View
                    key={x}
                    style={[
                      x === "NO"
                        ? styles.tableCellNo
                        : x === "HARGA"
                        ? styles.tableCellPrice
                        : styles.tableCell,
                      {
                        width: tableWidthMapper[x],
                        textAlign: ["HARGA", "JUMLAH"].includes(x)
                          ? "right"
                          : "left",
                      },
                    ]}
                  >
                    <Text>{x}</Text>
                  </View>
                ))}
              </View>
              <View>
                {items.map((item, idx) => {
                  const price =
                    item.discount_price > 0 ? item.discount_price : item.price;

                  const discounted = Math.round(
                    price - (price * discPct) / 100
                  );

                  return (
                    <View key={idx} style={styles.tableRow}>
                      <View
                        style={[
                          styles.tableCellNo,
                          { width: tableWidthMapper.NO },
                        ]}
                      >
                        <Text>{item.no}</Text>
                      </View>
                      <View
                        style={[
                          styles.tableCell,
                          { width: tableWidthMapper.BANYAK },
                        ]}
                      >
                        <Text>{countQuantity(item.quantity)}</Text>
                      </View>
                      <View
                        style={[
                          styles.tableCell,
                          { width: tableWidthMapper["NAMA BARANG"] },
                        ]}
                      >
                        <Text>{item.categoryName}</Text>
                      </View>
                      <View
                        style={[
                          styles.tableCellPrice,
                          {
                            width: tableWidthMapper.HARGA,
                            flexDirection: "row",
                          },
                        ]}
                      >
                        <Text
                          style={{
                            textAlign: "right",
                            textDecoration:
                              discPct > 0 ? "line-through" : "none",
                            marginRight: discPct > 0 ? 5 : 0,
                          }}
                        >
                          {price.toLocaleString("id")}
                        </Text>
                        {discPct > 0 ? (
                          <Text
                            style={{
                              textAlign: "right",
                              marginLeft: 10,
                            }}
                          >
                            {discounted.toLocaleString("id")}
                          </Text>
                        ) : null}
                      </View>
                      <View
                        style={[
                          styles.tableCell,
                          { width: tableWidthMapper.JUMLAH },
                        ]}
                      >
                        <Text style={{ textAlign: "right" }}>
                          {Number(item.subtotal).toLocaleString("id")}
                        </Text>
                      </View>
                    </View>
                  );
                })}
              </View>
              <View style={styles.footer}>
                <View>
                  {banks?.data.length !== 0 ? (
                    <>
                      <Text>
                        {banks?.data
                          .slice(0, 2)
                          .map(
                            (account) =>
                              `${account.bank_name}: ${account.value} `
                          )}
                      </Text>
                      <Text>
                        AN:{" "}
                        {banks?.data.length !== 0 &&
                          banks?.data[0].account_name}
                      </Text>
                    </>
                  ) : null}
                  <Text style={{ marginTop: "auto" }}>
                    Total Poin Anda:{" "}
                    {order?.data?.total_current_point}
                  </Text>
                  <Text style={{ marginTop: "auto" }}>
                    Poin Belanja Anda: {order?.data?.total_point_rewarded}
                  </Text>
                </View>
                <View style={{ width: "50%" }}>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text style={{ width: "75%", textAlign: "right" }}>
                      SUBTOTAL: Rp.
                    </Text>
                    <Text style={{ flex: 1, textAlign: "right" }}>
                      {order?.data.total_before.toLocaleString("id")}
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text
                      style={{
                        width: "75%",
                        textAlign: "right",
                      }}
                    >
                      {order?.data.discount_desc || "Diskon"}
                      {order?.data.discount
                        ? ` (${discPct}%) `.replace(".00", "")
                        : ""}
                      : Rp.
                    </Text>
                    <Text style={{ flex: 1, textAlign: "right" }}>
                      {order?.data.discount
                        ? `-${order?.data.discount.toLocaleString("id")}`
                        : "0"}
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text
                      style={{
                        width: "75%",
                        textAlign: "right",
                      }}
                    >
                      {order?.data.additional_cost_desc}: Rp.
                    </Text>
                    <Text style={{ flex: 1, textAlign: "right" }}>
                      {order?.data.additional_cost.toLocaleString("id")}
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text
                      style={{
                        width: "75%",
                        textAlign: "right",
                      }}
                    >
                      Poin Digunakan: Rp.
                    </Text>
                    <Text style={{ flex: 1, textAlign: "right" }}>
                      {!order?.data.is_use_point
                        ? 0
                        : `-${countUsedLoyaltyPoint(
                            order?.data.total_point_used,
                            mappedOptions?.point_currency
                          ).toLocaleString("id")}`}
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text
                      style={{
                        width: "75%",
                        textAlign: "right",
                      }}
                    >
                      TOTAL: Rp.
                    </Text>
                    <Text style={{ flex: 1, textAlign: "right" }}>
                      {order?.data.total_after_discount
                        ? order?.data.total_after_discount.toLocaleString("id")
                        : order?.data.total.toLocaleString("id")}
                    </Text>
                  </View>
                </View>
              </View>
            </Page>
          ))}
      </Document>
    </PDFViewer>
  );
};

export default Invoice;
