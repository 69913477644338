import { Form, Input, Button, Row, Col, Card } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import { useAuth } from 'libs/contexts/auth';

const Login = () => {
  const { authenticate } = useAuth();

  const onFinish = async (data) => {
    await authenticate(data);
  };

  return (
    <div
      style={{
        paddingTop: 250,
      }}
    >
      <Row gutter={16} align="middle" justify="space-around">
        <Col span={6}>
          <Card bordered title="Administrator Login">
            <Form
              name="normal_login"
              className="login-form"
              initialValues={{ remember: true }}
              onFinish={onFinish}
            >
              <Form.Item
                name="phone"
                rules={[
                  { required: true, message: 'Please input your Username!' },
                ]}
              >
                <Input prefix={<UserOutlined />} placeholder="Username" />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: 'Please input your Password!' },
                ]}
              >
                <Input
                  prefix={<LockOutlined />}
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>
              <Button type="primary" htmlType="submit">
                Log in
              </Button>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
